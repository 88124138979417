<template>
  <div class="tab-body-card" style="height: calc(100vh - 10vh)">
    <!-- Filters Section -->
    <CRow class="mb-4 gap-3" align-vertical="end">
      <!-- From Date -->
      <CCol md="2">
        <label>از تاریخ</label>
        <date-picker
          placeholder="درخواست از تاریخ ..."
          v-model="fromDate"
          format="jYYYY/jMM/jDD"
          @change="onFromDateChange"
          :clearable="true"
        />
      </CCol>

      <!-- To Date -->
      <CCol md="2">
        <label>تا تاریخ</label>
        <date-picker
          v-model="toDate"
          placeholder="... درخواست تا تاریخ"
          format="jYYYY/jMM/jDD"
          @change="onToDateChange"
          :clearable="true"
        />
      </CCol>

      <!-- Status Filter -->
      <CCol md="3">
        <label>وضعیت</label>
        <vSelect
          dir="rtl"
          v-model="selectedStatus"
          :options="statusOptions"
          placeholder="انتخاب وضعیت..."
          label="label"
          track-by="value"
        ></vSelect>
      </CCol>
    </CRow>

    <!-- No Data -->
    <no-data
      v-if="requestList.length === 0"
      :show="true"
      message="هیچ درخواستی یافت نشد"
      class="emptyTitle"
    />

    <!-- Display Requests as Cards -->
    <div v-else>
      <CRow style="height: calc(100vh - 25vh); overflow-y: auto">
        <CCol
          md="4"
          class="my-4"
          v-for="(request, index) in requestList"
          :key="index"
        >
          <RequestCard
            :request="request"
            @acceptRequest="acceptRequest"
            @rejectRequest="rejectRequest"
          />
        </CCol>
      </CRow>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import NoData from "../../../components/no-data.vue";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import RequestCard from "./RequestCard.vue";
import { apiUrlRoot } from "../../../constants/config";
export default {
  name: "RequestHistoryTab",
  components: {
    RequestCard,
    NoData,
    vSelect,
  },
  data() {
    return {
      searchQuery: "",
      fromDate: "",
      toDate: "",
      selectedStatus: null,
      statusOptions: [
        { value: "", label: "همه" },
        { value: 0, label: "در انتظار تایید" },
        { value: 1, label: "رد شده" },
        { value: 2, label: "تایید شده" },
      ],
      requestList: [],
      adviceModalState: false,
      farmModalState: false,
      currentRequest: {},
      currentRequestFarms: [], // This will hold the farms related to the current request
      currentRequestAdvice: [], // This will hold the advice data for the current request
    };
  },
  watch: {
    selectedStatus: function () {
      this.fetchData();
    },
  },
  methods: {
    ...mapActions("employerColleagues", [
      "GetAllRequest",
      "AcceptRequest",
      "RejectRequest",
    
    ]),

    async fetchData() {
      this.requestList = [];
      let result = await this.GetAllRequest({
        state: this.selectedStatus?.value ?? null,
        fullName: this.searchQuery,
        fromDate: this.fromDate,
        toDate: this.toDate,
      });
      if (result.succeeded) {
        this.requestList = result.data;
      } else {
        this.$notify({
          title: "پیام سیستم",
          text: result.message,
          type: "error",
        });
      }
      this.requestList = result.data;
    },
    async acceptRequest(request) {
      this.$confirm({
        message: `درخواست همکاری برای  ${request.fullName} تایید شود؟`,
        button: {
          no: "لغو",
          yes: "بله",
        },
        callback: async (confirm) => {
          if (confirm) {
            this.loading = true;
            this.loadingText = "در حال تایید درخواست";
            let result = await this.AcceptRequest({
              requestId: request.requestId,
            });
            this.loading = false;
            if (result.succeeded) {
              this.$notify({
                title: "انجام شد",
                text: "درخواست ارسال شده تایید شد <br /><br />",
                type: "success",
              });
              this.fetchData();
            } else {
              this.$notify({
                title: "خطا",
                text: `${result.message} <br /><br />`,
                type: "error",
              });
            }
          }
        },
      });
    },
    async rejectRequest(request) {
      this.$confirm({
        message: `درخواست همکاری برای  ${request.fullName} رد شود؟`,
        button: {
          no: "لغو",
          yes: "بله",
        },
        callback: async (confirm) => {
          if (confirm) {
            this.loading = true;
            this.loadingText = "در حال رد درخواست";
            let result = await this.RejectRequest({
              requestId: request.requestId,
            });
            this.loading = false;
            if (result.succeeded) {
              this.$notify({
                title: "انجام شد",
                text: "درخواست ارسال شده رد شد <br /><br />",
                type: "success",
              });
              this.fetchData();
            } else {
              this.$notify({
                title: "خطا",
                text: `${result.message} <br /><br />`,
                type: "error",
              });
            }
          }
        },
      });
    },

    onFromDateChange() {
      this.fetchData();
    },
    onToDateChange() {
      this.fetchData();
    },
    onStatusChange() {
      this.fetchData();
    },
  },

  mounted() {
    this.fetchData();
  },
};
</script>

<style>
.emptyTitle {
  text-align: center;
  font-size: 1.2rem;
  color: var(--secondary);
}

.fullscreen-modal {
  width: 90%;
  max-width: 90%;
  height: 95dvh;
  top: 0;
  display: flex;
  flex-direction: column;
  margin: auto;
}
</style>
