<template>
  <div class="main-card gap-20 d-flex flex-column">
    <CRow class="bodyItem">
      <CCol col="8" class="p-0">
        <CCol col="12" class="bodyItemValue y-center-g-5">
          <img
            :src="userImage"
            @error="() => (userImage = 'https://picsum.photos/200')"
            class="specialistImage"
            alt="Specialist Image"
          />
          <h4>{{ request.fullName }}</h4>
        </CCol>
        <CCol col="12" class="bodyItemValue">
          <span>شماره همراه :</span>
          <span>{{ request.phone }}</span>
        </CCol>
        <CCol col="12" class="bodyItemValue">
          <span>نوع کاربری :</span>
          <span>{{ request.farmerType }}</span>
        </CCol>
        <CCol col="12" class="bodyItemValue">
          <span>تعداد زمین های شما :</span>
          <span>{{ request.farmCount }}</span>
        </CCol>
        <CCol col="12" class="bodyItemValue">
          <span>تاریخ شروع همکاری :</span>
          <span>{{ request.startDateCollaboration }}</span>
        </CCol>
      </CCol>
      <CCol col="4 p-0">
        <CButton
          class="requestItemBtn flex-grow-1"
          color="info"
          @click="getFarms(request)"
          style="padding: 5px 12px; width: 100%; margin-top: 10px"
        >
          زمین ها
        </CButton>
        <CButton
          class="requestItemBtn flex-grow-1"
          color="danger"
          @click="endOfCooperation(request)"
          style="padding: 5px 12px; width: 100%; margin-top: 10px"
        >
          اتمام همکاری
        </CButton>
      </CCol>
    </CRow>
  </div>
</template>
  
  
  <style scoped>
.bodyItemValue {
  width: 100%;
}

.bodyItemValue h4 {
  font-size: 1rem;
  padding-right: 4px;
}

.specialistImage {
  width: 50px;
  height: 50px;
  object-fit: contain;
  border-radius: 50%;
}

.btn-view {
  background-color: var(--primary-light);
  color: #fff;
}

.specialistItemBtn {
  width: 100%;
  margin-top: 4px;
  padding: 6px 0px;
}
</style>
  <script>
export default {
  props: {
    request: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      userImage: "https://picsum.photos/200",
    };
  },
  computed: {},
  methods: {
    getFarms(request) {
      console.log(request);
      this.$emit("GetAllFarms", request);
    },
    endOfCooperation(request) {
      this.$emit("endOfCooperation", request);
    },
  },
};
</script>
  
  <style scoped>
/* Custom styles for RequestCard */
</style>
  