<template>
  <div>
    <body-with-header ads title="درخواست های جدید و کارفرمایان من">
      <CRow class="h-100">
        <vue-element-loading
          :active="loading"
          :text="loadingText"
          spinner="bar-fade-scale"
          color="var(--secondary)"
        />
        <CCol md="12">
          <CTabs fade variant="tabs">
            <!-- Tab 2: متخصصان (Specialists) -->
            <CTab active>
              <template #title>
                <div class="y-center-g-10 justify-content-center">
                  <span>درخواست های همکاری</span>
                  <font-awesome-icon icon="user-md" />
                </div>
              </template>
              <RequestHistoryTab />
            </CTab>

            <!-- Tab 3: کارمندان مزرعه (Farm Employees) -->
            <CTab>
              <template #title>
                <div class="y-center-g-10 justify-content-center">
                  <span>کارفرمایان</span>
                  <font-awesome-icon icon="users" />
                </div>
              </template>
              <AllEmployersTab />
            </CTab>
          </CTabs>
        </CCol>
      </CRow>
    </body-with-header>
  </div>
</template>
   
  <script>
import BodyWithHeader from "../../components/bodyWithHeader.vue";
import RequestHistoryTab from "./cooperationRequest/RequestHistoryTab.vue";
import AllEmployersTab from "./allEmployers/AllEmployersTab.vue";
export default {
  components: {
    BodyWithHeader,
    RequestHistoryTab,
    AllEmployersTab,
  },
  data() {
    return {
      loading: false,
      loadingText: "در حال بارگذاری...",
    };
  },
};
</script>
  