<template>
  <div style="min-height: 30dvh">
    <vue-element-loading
      :active="loading"
      text="دریافت لیست زمین ها..."
      spinner="bar-fade-scale"
      color="var(--secondary)"
    />
    <div
      v-if="farmsList.length > 0"
      class="body-wrapper farm-list-box d-grid grid-c-3 gap-20"
    >
      <general-farm-card
        v-for="(item, index) in farmsList"
        :key="index"
        :item="item"
      />
    </div>
    <no-data
      v-else
      class="pt-md-5"
      :show="!loading"
      message="متخصص هیچ زمینی ندارد"
    />
  </div>
</template>
  
    <script>
import { mapActions } from "vuex";
import { apiUrlRoot } from "../../../constants/config";
import NoData from "../../../components/no-data.vue";
import GeneralFarmCard from "../../../components/generalFarmCard.vue";
import { getCurrentUser } from "../../../utils/index";

export default {
  name: "FarmsList",
  components: { GeneralFarmCard, NoData },
  data() {
    return {
      farmsList: [],
      loading: false,
      loadingText: "در حال دریافت اطلاعات ...",
    };
  },
  props: ["farmerId"],
  watch: {
    advicerId: function () {
      this.getFarmsList();
    },
  },
  methods: {
    ...mapActions("employerColleagues", [
      "AdvicerAllEmployerFarm",
      "FarmuserAllEmployerFarm",
    ]),

    async getFarmsList() {
      this.farmsList = [];
      this.loading = true;
      this.loadingText = "در حال دریافت اطلاعات...";
      let result =
        getCurrentUser().type == "FarmUser"
          ? await this.FarmuserAllEmployerFarm({ farmerId: this.farmerId })
          : await this.AdvicerAllEmployerFarm({ farmerId: this.farmerId });
          console.log(result)
      if (result.data.length > 0) {
        this.farmsList = result.data;
        this.farmsList.forEach((element) => {
          element.productImage = `${apiUrlRoot}productImage/${element.productImage}`;
        });
      }
      this.loading = false;
    },
  },
  mounted() {
    this.getFarmsList();
  },
};
</script>
  
    <style scoped>
.advicerSubForm-title {
  float: right;
  width: 100%;
  text-align: right;
  padding: 8px 5px 7px 0px;
  font-size: 1em;
  background: linear-gradient(272deg, #c7f5c9, transparent);
  color: darkgreen;
}
.farm-list-box {
  overflow-x: hidden;
  overflow-y: auto;
}
</style>
  