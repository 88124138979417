<template>
  <div class="tab-body-card" style="height: calc(100vh - 10vh)">
    <!-- No Data -->
    <no-data
      v-if="requestList.length === 0"
      :show="true"
      message="هیچ درخواستی یافت نشد"
      class="emptyTitle"
    />

    <!-- Display Requests as Cards -->
    <div v-else>
      <CRow style="height: calc(100vh - 25vh); overflow-y: auto">
        <CCol
          md="4"
          class="my-4"
          v-for="(request, index) in requestList"
          :key="index"
        >
          <AllEmpolyerCard
            :request="request"
            @GetAllFarms="GetAllFarms"
            @endOfCooperation="endOfCooperation"
          />
        </CCol>
      </CRow>
    </div>
    <VueModal
      v-model="modelShowState"
      :title="modalTitle"
      wrapper-class="animate__animated animate__faster"
      in-class="animate__fadeIn"
      out-class="animate__fadeOut"
      modal-style="max-width: 50dvw !important; height:60dvh;"
    >
      <component v-bind:is="subForm" :farmerId="farmerId" />
    </VueModal>
  </div>
</template>
  
  <script>
import { mapActions } from "vuex";
import NoData from "../../../components/no-data.vue";
import "vue-select/dist/vue-select.css";
import AllEmpolyerCard from "./AllEmpolyerCard.vue";
import { apiUrlRoot } from "../../../constants/config";
import { getCurrentUser } from "../../../utils/index";
import AllEmployerFarms from "./AllEmployerFarms.vue";
export default {
  name: "AllEmpolyerTab",
  components: {
    AllEmpolyerCard,
    NoData,
    AllEmployerFarms,
  },
  data() {
    return {
      searchQuery: "",
      fromDate: "",
      toDate: "",
      selectedStatus: null,
      statusOptions: [
        { value: "", label: "همه" },
        { value: 0, label: "در حال بررسی" },
        { value: 1, label: "رد شده" },
        { value: 2, label: "تایید شده" },
      ],
      requestList: [],
      adviceModalState: false,
      farmModalState: false,
      currentRequest: {},
      farmerId: 0,
      modelShowState: false,
      modalTitle: "",
      subForm: null,
      currentRequestFarms: [], // This will hold the farms related to the current request
      currentRequestAdvice: [], // This will hold the advice data for the current request
    };
  },
  watch: {
    selectedStatus: function () {
      this.fetchData();
    },
  },
  methods: {
    ...mapActions("employerColleagues", [
      "AdvicerAllEmployer",
      "AdvicerEndOfCollaboration",
      "FarmuserAllEmployer",
      "FarmuserEndOfCollaboration",
    ]),

    async fetchData() {
      this.requestList = [];
      let result =
        getCurrentUser().type == "FarmUser"
          ? await this.FarmuserAllEmployer({})
          : await this.AdvicerAllEmployer({});
      if (result.succeeded) {
        this.requestList = result.data;
      } else {
        this.$notify({
          title: "پیام سیستم",
          text: result.message,
          type: "error",
        });
      }
      this.requestList = result.data;
    },
    async endOfCooperation(request) {
      this.$confirm({
        message: `اتمام همکاری با کارفرمای  ${request.fullName} ؟`,
        button: {
          no: "لغو",
          yes: "بله",
        },
        callback: async (confirm) => {
          if (confirm) {
            this.loading = true;
            this.loadingText = "در حال حذف کارفرما درخواست";
            let result;
            if (getCurrentUser().type == "Advicer") {
              result = await this.AdvicerEndOfCollaboration({
                farmerId: request.userTypeId,
              });
            } else if (getCurrentUser().type == "FarmUser") {
              result = await this.FarmuserEndOfCollaboration({
                farmerId: request.userTypeId,
              });
            }
            this.loading = false;
            if (result.succeeded) {
              this.$notify({
                title: "انجام شد",
                text: "همکاری شما با کرفرما تمام شد <br /><br />",
                type: "success",
              });
              this.fetchData();
            } else {
              this.$notify({
                title: "خطا",
                text: `${result.message} <br /><br />`,
                type: "error",
              });
            }
          }
        },
      });
    },
    async GetAllFarms(request) {
      this.farmerId = request.userTypeId;
      this.subForm = "AllEmployerFarms";
      this.modalTitle = `زمین های ${request.fullName}`;
      this.modelShowState = true;
    },
    onFromDateChange() {
      this.fetchData();
    },
    onToDateChange() {
      this.fetchData();
    },
    onStatusChange() {
      this.fetchData();
    },
  },

  mounted() {
    this.fetchData();
  },
};
</script>
  
  <style>
.emptyTitle {
  text-align: center;
  font-size: 1.2rem;
  color: var(--secondary);
}

.fullscreen-modal {
  width: 90%;
  max-width: 90%;
  height: 95dvh;
  top: 0;
  display: flex;
  flex-direction: column;
  margin: auto;
}
</style>
  